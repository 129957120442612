<template>
    <el-select placeholder="摘要类型" v-model="type" filterable :size="size">
        <el-option label="请选择" value="" />
        <el-option v-for="item in abstractTypes" :label="item.desc" :value="item.type" :key="item.type" />
    </el-select>
</template>

<script>
import StockBizCommon from 'js/StockBizCommon';

export default {
    name: 'EfAbstractTypeSelect',
    model: {
        prop: 'selected',
        event: 'change',
    },
    props: {
        selected: {
            type: [Number, String],
            default: '',
        },
        size: {
            type: String,
            default: 'small',
        },
    },
    data() {
        return {
            type: '',
            abstractTypes: StockBizCommon.allAbstractType2Desc(),
        };
    },
    watch: {
        selected: {
            handler(n, o) {
                //假设：null与''等价
                if (n == null) {
                    n = '';
                }
                this.type = n;
            },
            immediate: true,
        },
        type: {
            handler(n, o) {
                this.$emit('change', n);
            },
            immediate: true,
        },
    },
};
</script>

<template>
    <!--编辑页与详情页相同-->
    <div class="AbstractEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="brand" label-width="80px" :rules="rules" :model="form" @submit.native.prevent size="small">
                <el-form-item label="名称" prop="name">
                    <el-input v-model.trim="form.name" />
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <ef-abstract-type-select v-model="form.type" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import EfAbstractTypeSelect from 'components/EfAbstractTypeSelect';
import Util from 'js/Util';

export default {
    name: 'AbstractEdit',
    components: { EfAbstractTypeSelect },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            form: {
                name: '',
                type: '',
            },
            rules: {
                name: [{ required: true, message: '请填写名称', trigger: ['blur', 'change'] }],
                type: [{ required: true, message: '请选择类型', trigger: 'change' }],
            },
        };
    },
    mounted() {
        this.$efApi.stockAbstractApi.detail(this.code).then((rst) => {
            Util.copyProperties(rst, this.form);
        });
    },
    methods: {
        handleSave() {
            this.$refs.brand.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                await this.$efApi.stockAbstractApi.update(this.code, this.form);
                this.goBackAndReload();
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
